
import './LandingPage.css';

function LandingPage(props) {
   
    return (
      <div className="Section Landing-Page">
        <div className="Content-Area">
          <div className="Profile-Picture">
           <img src="./ProfilePicture.jpg" />
          </div>
          <div className="Title">
            <span>REY.</span>

            {/* <button onClick={() => props.scrollTo(".About-Page")}> */}
            {/* Scroll to */}
            {/* </button> */}
          </div>
          <div className="Job-Title">
            <span>FULL STACK DEVELOPER. </span>
            {/* Job
            <button onClick={() => props.scrollTo(".About-Page")}>
            Scroll to
            </button> */}
          </div>
          <div className="Github-Display">
           <img src="https://ghchart.rshah.org/FreyaRhianna" alt="2016rshah's Github chart" />

          </div>
          <div className="Projects-Link">
            <span onClick={() => props.scrollTo(".Projects-Page")}>PROJECTS</span>
            <span onClick={() => props.scrollTo(".Testimonials-Page")}>TESTIMONIALS</span>
          </div>
          {/* <div className="About-Me-Link">
            About-Me-Link
          </div> */}
          <div className="Social-Bar">
            <a href="https://github.com/FreyaRhianna" ><img src="./GitHubIcon.svg" /> </a>
            <a href="https://www.linkedin.com/in/freya-sheer-hardwick/"> <img src="./LinkedinIcon.svg" /> </a>
            <a href="https://www.researchgate.net/profile/Freya-Sheer-Hardwick" ><img src="./ResearchGateIcon.svg" /></a>
            <a href=""><img src="./MediumIcon.svg" /></a>

          </div>

        </div>
       
      </div>
    );
  }
  
  export default LandingPage;
  