import './ProjectsPage.css';

function ProjectsPage(props) {
   
    return (
      <div className="Section Projects-Page">
        <div className="Accent-Line Accent-Line-Top"> </div>
        <div className="Accent-Line Accent-Line-Bottom"> </div>

        <div className='Content-Area'>
        <div className="Title">
            <span>PROJECTS.</span>
          </div>
          <div className="Project-Panel Project-One">
          <div className="Project-Content">
                <div className="Project-Picture">
                  {/* <img src="AcademicPromotions.svg"></img> */}
                </div>
                <div className="Project-Desc">
                  <div className="Tools-List">
                    <img src="node-js-svgrepo-com.svg"></img>
                    <img src="react-svgrepo-com.svg"></img>
                    <img src="ethereum-svgrepo-com.svg"></img>
                    <img src="git-svgrepo-com.svg"></img>
                    <img src="postgresql-svgrepo-com.svg"></img>

                  </div>
                  <div className="Project-Title">
                      <span>FOODCHAIN</span>
                  </div>
                  <div className="Project-Summary">
                      <span>A <span className="Accented">web application </span> that uses the ethereum <span className="Accented">blockchain </span> and metamask wallets to track donations made to <span className="Accented">foodbanks</span>.</span>
                  </div>
                  <div className="Project-Partner">
                      <span> <span className="Partner-Tag"> PARTNER:</span> <img src="NTU.png"></img></span>
                  </div>

                </div>
              
          </div>



          </div>
          <div className="Project-Panel Project-Two">
             <div className="Project-Content">
                <div className="Project-Picture">
                  {/* <img src="AcademicPromotions.svg"></img> */}
                </div>
                <div className="Project-Desc">
                  <div className="Tools-List">
                    <img src="html-svgrepo-com.svg"></img>
                    <img src="php-svgrepo-com.svg"></img>
                    <img src="js-svgrepo-com.svg"></img>
                    <img src="git-svgrepo-com.svg"></img>
                    <img src="linux-svgrepo-com.svg"></img>

                  </div>
                  <div className="Project-Title">
                      <span>PROMOTIONS</span>
                  </div>
                  <div className="Project-Summary">
                      <span>A <span className="Accented">web application </span> to <span className="Accented">replace</span> the <span className="Accented">paper based</span> academic promotion system within the university.</span>
                  </div>
                  <div className="Project-Partner">
                      <span> <span className="Partner-Tag"> PARTNER:</span> <img src="RHUL.jpg"></img></span>
                  </div>

                </div>
              
              </div>
          </div>
          <div className="Project-Panel Project-Three">
          <div className="Project-Content">
                <div className="Project-Picture">
                  {/* <img src="AcademicPromotions.svg"></img> */}
                </div>
                <div className="Project-Desc">
                  <div className="Tools-List">
                    <img src="html-svgrepo-com.svg"></img>
                    <img src="ethereum-svgrepo-com.svg"></img>
                    <img src="js-svgrepo-com.svg"></img>
                    <img src="metamask-svgrepo-com.svg"></img>
                    <img src="git-svgrepo-com.svg"></img>

                  </div>
                  <div className="Project-Title">
                      <span>Satellite vis</span>
                  </div>
                  <div className="Project-Summary">
                      <span>A web application for <span className="Accented"> visualising </span> AI analysis of <span className="Accented">satellite data</span> and trading for <span className="Accented">cryptocurrency</span>.</span>
                  </div>
                  <div className="Project-Partner">
                      <span> <span className="Partner-Tag"> PARTNER:</span> <img src="SPACECHAIN.png"></img></span>
                  </div>

                </div>
              
          </div>
          </div>
          <div className="Project-Panel Project-Four">
          <div className="Project-Content">
                <div className="Project-Picture">
                  {/* <img src="AcademicPromotions.svg"></img> */}
                </div>
                <div className="Project-Desc">
                  <div className="Tools-List">
                    <img src="rails-svgrepo-com.svg"></img>
                    <img src="react-svgrepo-com.svg"></img>
                    <img src="node-js-svgrepo-com.svg"></img>
                    <img src="git-svgrepo-com.svg"></img>
                  </div>
                  <div className="Project-Title">
                      <span>Fastlaw</span>
                  </div>
                  <div className="Project-Summary">
                      <span>A <span className="Accented"> micro-service </span> system for providing <span className="Accented">legal advice </span> quickly and with transparency.</span>
                  </div>
                  <div className="Project-Partner">
                      <span> <span className="Partner-Tag"> PARTNER:</span> <img src="SWANSEA.png"></img></span>
                  </div>

                </div>
              
          </div>

          </div>

          {/* <div className="Landing-Link"></div>
          <div className="About-Me-Link"></div> */}

          {/* <button onClick={() => props.scrollTo(".Landing-Page")}>
          Scroll to
          </button> */}
         
        </div>
       

     
      </div>
    );
  }
  
  export default ProjectsPage;
  