import './App.css';
import LandingPage from "./components/LandingPage/LandingPage";
import ProjectsPage from "./components/ProjectsPage/ProjectsPage";
import TestimonialsPage from "./components/TestimonialsPage/TestimonialsPage";
import ParticleMask from "./components/ParticleMask/ParticleMask";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import "./fonts/Staatliches-Regular.ttf"
import { useLayoutEffect, useRef, useState, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  const component = useRef();
  const slider = useRef();
  var tween;
  let panels;


  function scrollTo(query){
    if(!isMobile){
      var targetElem = document.querySelector(query),
      y = targetElem;
      let totalScroll = tween.scrollTrigger.end - tween.scrollTrigger.start,
      totalMovement = (panels.length - 1) * targetElem.offsetWidth;
      y = Math.round(tween.scrollTrigger.start + (targetElem.offsetLeft / totalMovement) * totalScroll);
    
      gsap.to(window, {
        scrollTo:{ y: y},
        overwrite: "auto",
        duration: 1
      });
    }else{
      var targetElem = document.querySelector(query);
      console.log("doing somethign")
      gsap.to(window, {
        scrollTo: targetElem,
        overwrite: "auto",
        duration:1
      });
    }

  }
  useLayoutEffect(() => {
    if(!isMobile){  
      let ctx = gsap.context(() => {
        console.log(slider.current)
        panels = gsap.utils.toArray(".Section");
        tween = gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 1,
            snap: 1 / (panels.length - 1),
            end: () => "+=" + slider.current.offsetWidth
          }
        });
      }, component);
      return () => ctx.revert();
    }
  });
  return (
    <div className="App" ref={component}>

      <ParticleMask isMobile={isMobile}/>

      <div ref={slider} className='Container'> 
      {/* <button onClick={scrollTo}>
        Scroll to
      </button> */}

        <LandingPage scrollTo={scrollTo} />
        
        <ProjectsPage  scrollTo={scrollTo}/>
        <TestimonialsPage  scrollTo={scrollTo}/>

        </div>
    </div>
  );
}

export default App;
