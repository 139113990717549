import './TestimonialsPage.css';

function TestimonialsPage(props) {
   
    return (
      <div className="Section Testimonials-Page">
        <div className="Accent-Line Accent-Line-Top"> </div>
        <div className="Accent-Line Accent-Line-Bottom"> </div>
        <div className="Content-Area"> 
         <div className="Title">
            <span>TESTIMONIALS.</span>
          </div>
          <div className="Testimonial-Container First-Testimonial">
            <div className="Quote">
              <span> "The contributions from Freya on both smart contracts and the front-end programming have been really accelerating the progress. Especially when we came over a few challenging new concepts, Freya proposed effective and efficient solutions and realised them with perfect implementation. "</span>
            </div>
            <div className="Attribution">
                <span>Ziheng Xiang - Spacechain</span>
              </div>
          </div>
          <div className="Testimonial-Container Second-Testimonial">
            <div className="Quote">
            <span> "Freya is definitely a spectacular team member for any collaboration. Her communications with team members have been always a pleasure for all others. And the profound knowledge in web3 can incite the team in every aspect."</span>
            </div>
            <div className="Attribution">
                <span>Ziheng Xiang - Spacechain</span>
              </div>
          </div>
          <div className="Testimonial-Container Third-Testimonial">
            <div className="Quote">
              <span> "It was great working with Freya on a funded academic / research project focused on the integration of the circularity theory and blockchain technology. Freya demonstrated excellent knowledge and skills in relation to blockchain technology. 
Will definitely recommend or work with Freya again in the future."</span>            
            </div>
            <div className="Attribution">
                <span>Nemitari Ajienka - Nottingham trent university</span>
              </div>
          </div>
         
        
          {/* Landing
          <button onClick={() => props.scrollTo(".Projects-Page")}>
          Scroll to
          </button> */}
        
        </div>
        
      </div>
    );
  }
  
  export default TestimonialsPage;
  