import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { loadPolygonMaskPlugin } from "tsparticles-plugin-polygon-mask";
import { useCallback } from "react";

import './ParticleMask.css';

export default function ParticleMask(props) {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        await loadFull(engine);
        await loadPolygonMaskPlugin(engine);

    }, []);


  const options = {
    name: "Polygon Mask",
    position: {"x": 10, "y": 10},
    interactivity: {
      events: {
        onClick: {
          enable: false,
          mode: "push"
        },
        onDiv: {
          elementId: "repulse-div",
          enable: false,
          mode: "repulse"
        },
        onHover: {
          enable: true,
          mode: "bubble",
          parallax: {
            enable: false,
            force: 2,
            smooth: 10
          }
        }
      },
      modes: {
        bubble: {
          distance: 40,
          duration: 2,
          opacity: 8,
          size: 6
        },
        connect: {
          distance: 80,
          links: {
            opacity: 0.5
          },
          radius: 20
        },
        grab: {
          distance: 400,
          links: {
            opacity: 1
          }
        },
        push: {
          quantity: 4
        },
        remove: {
          quantity: 2
        },
        repulse: {
          distance: 200,
          duration: 0.4
        },
        slow: {
          active: false,
          radius: 0,
          factor: 1
        }
      }
    },
    particles: {
        color: {
            value: "#ff0000",
            animation: {
              enable: true,
              speed: 20,
              sync: true
            }
          },
      links: {
        blink: false,
        color: "random",
        consent: false,
        distance: 30,
        enable: true,
        opacity: 0.4,
        width: 1
      },
      move: {
        enable: true,
        outModes: "bounce",
        speed: 0.4
      },
      number: {
        limit: 0,
        value: (props.isMobile)? 50 :500
      },
      opacity: {
        animation: {
          enable: true,
          speed: 2,
          sync: false
        },
        value: {
          min: 0.1,
          max: 0.4
        }
      },
      shape: {
        type: "circle"
      },
      size: {
        animation: {
          enable: false,
          minimumValue: 0.1,
          speed: 40,
          sync: false
        },
        random: true,
        value: 2
      }
    },
    polygon: {
      draw: {
        enable: true,
        lineColor: "rgba(255, 176, 176,01)",
        lineWidth: 2
      },
      enable: true,
      move: {
        radius: 10
      },
      inline: {
        arrangement: "equidistant"
      },
      scale: (props.isMobile)? 1.5 :3,
      type: "inline",
      url: "/city-line.svg",
      position: (props.isMobile)? {"x": 0, "y":70} : {"x": 0, "y":55},
    },
    background: {
    //    color: "rgba(255, 234, 234,0)",
      //color:"rgba(112, 128, 144,1)",
      image: "",
      position: "50% 50%",
      repeat: "no-repeat",
      size: "cover",

    }
  } ;

  return (
        <div className="particleContainer"><Particles id="tsparticles" options={options} init={particlesInit} /></div>
      
  );
}